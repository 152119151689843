import React, { createContext, useContext, useEffect, useState } from "react";
import { paymentMethodsFetchController } from "../dataController/dataController";
import { useLocation } from "./locationContext";
import { useQuote } from "./../context/quoteContext";

const PaymentMethodContext = createContext();
export const usePaymentMethod = () => {
  return useContext(PaymentMethodContext);
};
export const PaymentMethodProvider = ({ children }) => {
  const { geolocation } = useLocation();
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [paymentMethodLink, setPaymentMethodLink] = useState(null);
  const { data, isLink } = useQuote();

  const [availablePaymentMethods, setAvailablePaymentMethods] = useState({
    status: false,
  });

  useEffect(() => {
    let country = null;

    const fetchData = async () => {
      if (isLink && data?.quote.from_id) {
        country = data.quote.from_id;
      } else {
        country = localStorage.getItem("country_id") ?? geolocation?.country_id;
      }

      if (country) {
        try {
          const result = await paymentMethodsFetchController.fetchData(country);

          // if (country === "155") {
          //   result.data.methods["Pix"] = "";
          // }

          // if (country === "158") {
          //   result.data.methods["Rebill"] = "";
          // }

          // if (country === "52" || country === "165") {
          //   console.log("dlocal MP active");
          //   result.data.methods["DlocalMP"] = "";
          // }

          setAvailablePaymentMethods(result.data);
        } catch (error) {
          console.warn(error);
        } finally {
          // console.warn("done");
        }
      }
    };
    fetchData();
  }, [geolocation, isLink]);

  const contextValue = {
    paymentMethod,
    setPaymentMethod,
    availablePaymentMethods,
    setPaymentMethodLink,
    paymentMethodLink,
  };

  return (
    <PaymentMethodContext.Provider value={contextValue}>
      {children}
    </PaymentMethodContext.Provider>
  );
};
