import React, { useCallback, useEffect, useState } from "react";
import Chubb from "../Chubb";
import Illustrations from "../../assets/illustrations";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { FormControl, FormGroup } from "@mui/material";
import Checkbox from "@material-ui/core/Checkbox";
import CheckIcon from "@mui/icons-material/Check";
import { mapUpgradesData } from "../../helpers/dataMapper";
import { pricingPOSTController } from "../../dataController/dataController";
import { useFormData } from "../../context/formContext";
import { useQuote } from "../../context/quoteContext";

const AdditionalForm = ({
  control,
  Controller,
  errors,
  upgrades,
  currency_symbol,
  setValue,
  form,
  setPurchaseDetail,
  geolocation
}) => {

  const { t } = useTranslation();
  // const { parsedData } = useFormData();
  const { getFormData, setFormData, parsedData } = useFormData();

  const { data, isLink } = useQuote();
  const [installments, setInstallments] = useState(null);
  let dataLayer = window.dataLayer || [];

  //? UPGRADES INSTALLMENTS (ONLY FOR ARGENTINA)

  useEffect(() => {
    const total_installments = 12;
    const _installments = localStorage.getItem("country_id") === "164" || geolocation?.country === "AR" ? total_installments : null;
    setInstallments(_installments);
  }, [geolocation]);


  const formatNum = (numero) => {
    return new Intl.NumberFormat("es-AR").format(numero);
  }

  const onChange = useCallback(
    (e, upgrade) => {
      const isChecked = e.target.checked;
      setValue(upgrade, isChecked);
      form.current.requestSubmit();
    },
    [form]
  );

  const handleSubmissionError = (error) => {
    console.error("Error during submission:", error);
  };

  // useEffect(() => {

  //   if (!isLink) {
  //     try {

  //       const { currency_symbol, total_price, name, product_id } = data.quote;
  //       const email = localStorage.getItem("pax_email") ?? null;
  //       const impression = {
  //         "event": "begin_checkout",
  //         "currency": currency_symbol,
  //         "value": total_price,
  //         "email": email,
  //       };
  //       const voucher = localStorage.getItem("voucher") ?? null;
  //       if (voucher) {
  //         impression["coupon"] = voucher;
  //       }

  //       dataLayer.push({ ecommerce: null });

  //       impression["ecommerce"] = {
  //         items: [
  //           {
  //             "item_id": product_id,
  //             "item_name": name,

  //           }
  //         ]
  //       }

  //       dataLayer.push(impression);

  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }
  // }, []);

  useEffect(() => {

    if (parsedData && parsedData.date_from !== "NaN/NaN/NaN") {

      const fetchData = async () => {

        try {
          const result = await pricingPOSTController.fetchData({
            body: parsedData,
          });
          setPurchaseDetail((prev) => ({ ...prev, ...result.data }));
        } catch (error) {
          handleSubmissionError(error);
        } finally {
          console.info("Submission done <AdditionalForm>");
        }
      };
      fetchData();
    }
  }, [parsedData, form]);

  const renderSummary = (index, name, title, id, price, description) => {
    return (
      <FormGroup>
        <div className="clear-both flex items-start">
          <Controller
            name={`upgrade_${id}_${index}`}
            control={control}
            render={({ field }) => (
              <Checkbox
                {...field}
                defaultChecked={field.value}
                onChange={(e) => onChange(e, `upgrade_${id}_${index}`)}
                style={{
                  color: "#2F6DE0",
                  fontSize: "32px",
                  width: "inherit",
                }}
              />
            )}
          />
          <div className="w-[40px]">
            <Illustrations name={name} />
          </div>
          <div>
            <Typography fontSize={16} fontWeight={600} color="#1B2225">
              {title}
            </Typography>
            <Typography fontSize={14} color="#323334">
              {description}
            </Typography>
            <Typography fontSize={14} fontWeight={600} color="#1B2225">
              {installments == null ? (currency_symbol + " " + formatNum(price)) : (<span>{installments} cuotas de ${formatNum(Math.round(price / installments))} {currency_symbol}</span>)}
            </Typography>
          </div>
        </div>
      </FormGroup>
    );
  };

  const renderItems = useCallback(() => {
    const forms = [];

    Object.keys(upgrades).forEach((key, i) => {
      const rows = mapUpgradesData(upgrades[key]);
      forms.push(
        <div key={i + 1}>
          <h1 className="font-bold text-2xl text-[#006FE8] mb-5">
            {`${t("steps.billingData.passenger")} ${i + 1} - ${parsedData[`beneficiary[name][${i}]`]
              } ${parsedData[`beneficiary[lastname][${i}]`]}`}
          </h1>
          {rows.length
            ? rows.map((row, index) => {
              return (<div className="mb-5" key={index}>
                <Chubb
                  summary={renderSummary(
                    i,
                    row.name.trim(),
                    row.title.trim(),
                    row.id,
                    row.price,
                    row.description
                  )}
                  defaultExpanded={false}
                >
                  {installments != null && <span className="font-semibold ml-4 pb-4 block">Precio final: ${formatNum(row.price)} ARS </span>}
                  <div className="flex ml-4">
                    <CheckIcon style={{ color: "#79BF30" }} />
                    <p className="ml-1">{row.note}</p>
                  </div>
                </Chubb>
              </div>)
            })
            : null}
        </div>
      );

    });

    return <>{forms}</>;
  }, [control, Controller, errors, upgrades, parsedData]);

  return <FormControl fullWidth>{renderItems()}</FormControl>;
};

export default AdditionalForm;
