import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "@mui/material";
import { CreditCard, AccountBalance, Check, Star } from "@material-ui/icons";
import PixIcon from "@mui/icons-material/Pix";
import { makeStyles } from "@mui/styles";
import MP from "../../assets/icons/MP";
import { usePaymentMethod } from "../../context/paymentMethodContext";
import Paypal from "../../assets/icons/Paypal";
import { useFormData } from "../../context/formContext";
import { useActionButton } from "../../context/actionButtonContext";
import { useLocation } from "../../context/locationContext";
import MercadoPagoHandler from "../../handlers/MercadoPagoHandler";
import { useQuote } from "../../context/quoteContext";
import { pricingPOSTController } from "../../dataController/dataController";
import WhatsappSupport from "../../components/WhatsappSupport/WhatsappSupport";
import { useLoader } from "../../context/loaderContext";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import DlocalMercadoPago from "../../components/DlocalMercadoPago";

const useStyles = makeStyles(
  () => ({
    paymentButton: {
      backgroundColor: "#ffffff !important",
      color: "#2F6DE0 !important",
      paddingTop: "20px !important",
      paddingBottom: "20px !important",
      justifyContent: "space-between !important",
      "&:hover": {
        backgroundColor: "#2F6DE0 !important",
        color: "#ffffff !important",
      },
      "&.selected": {
        backgroundColor: "#2F6DE0 !important",
        color: "#ffffff !important",
      },
    },
  }),
  { classNamePrefix: "paymentButton" }
);

const dateFromFormatted = (dateStr) => {
  return dateStr.replace(/.*?(\d+)\sde\s(\w+)\sde\s(\d+)/, (match, day, month, year) => { const months = { enero: '01', febrero: '02', marzo: '03', abril: '04', mayo: '05', junio: '06', julio: '07', agosto: '08', septiembre: '09', octubre: '10', noviembre: '11', diciembre: '12' }; return `${months[month.toLowerCase()]}/${day.padStart(2, '0')}/${year}`; });
}

const getDiffDays = (date1) => {
  let Difference_In_Time = new Date(date1).setHours(0, 0, 0, 0) - new Date().setHours(0, 0, 0, 0);
  return Math.round(Difference_In_Time / (1000 * 3600 * 24));
}

// const PaymentButton = ({ method, label, icon, onClick, selected }) => {
//   const classes = useStyles();

//   console.log('method --->', method);

//   // TRANSFER 10% OFF
//   if (method === 'deposit') {
//     setDepositActive(true);
//   }

//   return (
//     <div className="mb-6">
//       <Button
//         className={`${classes.paymentButton} ${selected ? "selected" : ""}`}
//         variant="contained"
//         fullWidth
//         startIcon={icon}
//         endIcon={selected ? <Check /> : <> </>}
//         onClick={() => onClick(method)}
//         style={{ opacity: 1 }}
//       >
//         <Typography fontSize={20}>{label}</Typography>
//       </Button>
//     </div>
//   );
// };

const PaymentMethods = ({ purchaseDetail, setPurchaseDetail, setDepositDiscount }) => {
  const { t } = useTranslation();
  const {
    paymentMethod,
    setPaymentMethod,
    availablePaymentMethods,
    paymentMethodLink,
  } = usePaymentMethod();
  const { setFormData, parsedData } = useFormData();
  const { setActionButtonEnabled, setActionButtonAvailable } =
    useActionButton();
  const { geolocation } = useLocation();
  const { isLink } = useQuote();
  const { setLoader } = useLoader();

  const [depositActive, setDepositActive] = useState(false);
  const [requestError, setRequestError] = useState(null);

  // setActionButtonAvailable(true);

  // TRANSFER 10% OFF 

  const handleSubmissionError = (error) => {
    console.error("Error during submission:", error);
  };

  const PaymentButton = ({ method, label, icon, onClick, selected, id, className }) => {
    const classes = useStyles();

    // TRANSFER 10% OFF

    if (method === 'deposit') {
      setDepositActive(true);
    }

    return (
      <button
        id={id}
        className={"btn-payment-wrapper" +
          (className ? ` ${className}` : "")
        }
        sx={{ width: "100%" }}
      >
        <div className="mb-6">
          <Button
            className={`${classes.paymentButton} ${selected ? "selected" : ""}`}
            variant="contained"
            fullWidth
            startIcon={icon}
            endIcon={selected ? <Check /> : <> </>}
            onClick={() => onClick(method)}
            style={{ opacity: 1 }}
          >
            <Typography fontSize={20}>{label}</Typography>
          </Button>
        </div>
      </button>
    );
  };

  const paymentMethods = {
    cielo: [
      {
        name: "cielo",
        label: t("steps.paymentMethods.methods.creditCard"),
        icon: <CreditCard />,
        id: "btnCielo"
      },
    ],
    dlocal: [
      {
        name: "dlocal",
        label: t("steps.paymentMethods.methods.creditOrDebitCard"),
        icon: <CreditCard />,
        id: "btnTarjetaCreditoDlocal"
      },
    ],
    decidir: [
      {
        name: "decidirCredit",
        label: t("steps.paymentMethods.methods.creditCard"),
        icon: <CreditCard />,
        id: "btnTarjetaCreditoPrisma"
      },
      {
        name: "decidirDebit",
        label: t("steps.paymentMethods.methods.debitCard"),
        icon: <CreditCard />,
        id: "btnTarjetaDebitoPrisma"
      },
    ],
    pix: [
      {
        name: "Pix",
        label: t("steps.paymentMethods.methods.Pix"),
        icon: <PixIcon />,
        id: "btnPix"
      },
    ],
    deposit: [
      {
        name: "deposit",
        label: t("steps.paymentMethods.methods.transfer"),
        icon: <Star style={{ fill: "#FFD066" }} />,
        id: "btnTransferenciaBancaria"
        // icon: <AccountBalance />,
      },
    ],
    mercadopago: [
      {
        name: "mp",
        label: t("steps.paymentMethods.methods.mp"),
        icon: <MP />,
        id: "btnMercadoPago",
        className: "btnContinuarMediosPago"
      },
    ],
    paypal: [
      {
        name: "paypal",
        label: "PAYPAL",
        icon: <Paypal />,
        id: "btnPaypal",
        className: "btnContinuarMediosPago"
      },
    ],
    rebill: [
      {
        name: "rebillTransfer",
        label: t("steps.paymentMethods.methods.rebill.transfer"),
        icon: <AccountBalanceIcon />,
        id: "btn_transferencia_bancaria_ext"
      },
      {
        name: "rebillCash",
        label: t("steps.paymentMethods.methods.rebill.cash"),
        icon: <AttachMoneyIcon />,
        id: "btn_efectivo_ext"
      },
    ],
    dlocalmp: [
      {
        name: "dlocalmp",
        label: t("steps.paymentMethods.methods.mp"),
        icon: <MP />,
        id: "btn_mercado_pago_ext",
      },
    ],
  };

  const handleButtonClick = (method) => {
    const m = method === "decidirCredit" || method === "decidirDebit" ? "decidir" : method;

    if (!isLink) {
      // if (parsedData) {
      if (parsedData && parsedData.date_from !== "NaN/NaN/NaN") {

        if (m === 'deposit') {
          parsedData["payment_method"] = "deposit";
        } else {
          if (parsedData.hasOwnProperty("payment_method")) {
            delete parsedData["payment_method"];
          }
        }

        setDepositDiscount(m === 'deposit' ? true : false);

        parsedData["method"] = m;

        const fetchData = async () => {
          try {

            // console.log("SELECTED PAYMENTBTN -------");
            setLoader(true);
            const result = await pricingPOSTController.fetchData({
              body: parsedData,
            });

            // console.warn('PRICING ----> Steps/PaymentMethods');
            // console.log(result);
            setPurchaseDetail((prev) => ({ ...prev, ...result.data }));

          } catch (error) {
            handleSubmissionError(error);
          } finally {
            setLoader(false);
            console.info("Submission done");
          }
        };
        fetchData();
      }
    }

    setPaymentMethod(method);
    setFormData((prev) => ({
      ...prev,
      ...{ method: m, payment_method: m },
    }));
  };

  const renderButtons = useCallback(() => {
    const { status, methods } = availablePaymentMethods || {};
    const { country } = geolocation || {};

    if (!status || !methods) {
      return null;
    }

    if (isLink) {
      return paymentMethods[paymentMethodLink]?.map((methodInfo) => (
        <PaymentButton
          key={methodInfo?.name}
          method={methodInfo?.name}
          label={methodInfo?.label}
          icon={methodInfo?.icon}
          onClick={handleButtonClick}
          selected={paymentMethod === methodInfo?.name}
        />
      ));
    }

    return Object.entries(methods).flatMap(([key, value]) => {

      //? DLOCAL + MERCADO PAGO (CL, MX)

      const dlocal_mp_available_countries = ['CL', 'MX'];

      if (!dlocal_mp_available_countries.includes(country) && (key === "DlocalMP")) {
        return [];
      }

      //? REBILL (CO)

      const rebill_available_countries = ['CO'];

      if (!rebill_available_countries.includes(country) && key === "Rebill") {
        return [];
      }

      //? DLOCAL 
      const dlocal_available_countries = ['CO', 'CL', 'MX', 'PE', 'UY', 'BR'];

      if (!dlocal_available_countries.includes(country) && key === "Dlocal") {
        return [];
      }

      //? TRANSFERNCIA

      if (key === "Deposit" && getDiffDays(dateFromFormatted(purchaseDetail?.date_from)) < 3) {
        return [];
      }

      // PRISMA DISABLED

      // if (key === "Decidir") { 
      //   return [];
      // }

      return paymentMethods[key.toLowerCase()]?.map((methodInfo) => (
        <PaymentButton
          key={methodInfo?.name}
          method={methodInfo?.name}
          label={methodInfo?.label}
          icon={methodInfo?.icon}
          onClick={handleButtonClick}
          selected={paymentMethod === methodInfo?.name}
          id={methodInfo?.id}
          className={methodInfo?.className}
        />
      ));
    });
  }, [
    availablePaymentMethods,
    paymentMethod,
    geolocation,
    isLink,
    paymentMethodLink,
  ]);

  useEffect(() => {

    console.log('paymentMethod ----->', paymentMethod);

    if (paymentMethod) {
      setActionButtonEnabled(true);
    } else {
      setActionButtonEnabled(false);
    }

    if (paymentMethod === "mp" || paymentMethod === "mercadopago" || paymentMethod === "dlocalmp") {
      setActionButtonAvailable(false);
    } else {
      setActionButtonAvailable(true);
    }
  }, [paymentMethod]);

  useEffect(() => {

    // WIP 
    setPaymentMethod(null);

    return () => {
      setActionButtonEnabled(true);
      setActionButtonAvailable(true);
    };
  }, []);

  const controlError = (error) => {
    setRequestError(error);
  };

  return (
    <>
      {requestError && !isLink && <WhatsappSupport reserve_id={reserveData?.reserve_id} />}
      <section>
        <h1 className="font-bold text-2xl text-[#006FE8] mb-6">
          {t("steps.paymentMethods.title")}
        </h1>

        {depositActive &&
          <div className="depositBoxNotification">{t("steps.paymentMethods.transferMessage")}</div>
        }

        {renderButtons()}

        {(paymentMethod === "mp" || paymentMethod === "mercadopago") && (
          <MercadoPagoHandler
            parsedData={parsedData}
            purchaseDetail={purchaseDetail}
            controlError={controlError}
          />
        )}

        {(paymentMethod === "dlocalmp") && (
          <DlocalMercadoPago  
            parsedData={parsedData}
            purchaseDetail={purchaseDetail}
            controlError={controlError}
          />
        )}

      </section>
    </>
  );
};

export default PaymentMethods;
