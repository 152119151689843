import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "./locationContext";
import { useTranslation } from "react-i18next";

const WhatsappContext = createContext({
  data: null,
});

export function WhatsappProvider({ children }) {
  const { geolocation } = useLocation();
  const [Whatsapp, setWhatsapp] = useState("");
  const { i18n } = useTranslation();

  const chatCenter = (country_id) => {
    return [52, 135, 158, 199, 157, 6, 161, 155, 165].includes(
      parseInt(country_id)
    );
  };

  useEffect(() => {
    if (geolocation) {
      let today_day = geolocation?.today_day;
      let today_hour = geolocation?.today_hour;

      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has("today_day") || urlParams.has("today_hour")) {
        if (urlParams.get("today_day")) {
          today_day = urlParams.get("today_day").toString();
        }
        if (urlParams.get("today_hour")) {
          today_hour = urlParams.get("today_hour").toString();
        }
      }

      let whatsapp_number = "";

      // if (today_day === "6" || (today_day === "7" && today_hour < "22:01")) {
      if (
        (today_day === "5" && today_hour < "15:01") ||
        today_day === "6" ||
        (today_day === "7" && today_hour < "22:01")
      ) {
        whatsapp_number = "5491122647575";
      } else {
        whatsapp_number = chatCenter(geolocation.country_id)
          ? "5491122647575"
          : geolocation.header_whatsapp.substring(1);

        if (
          geolocation?.country_id === 155 ||
          geolocation?.country_id === 201 ||
          i18n.language === "pt-BR"
        ) {
          whatsapp_number = "5548988523408";
        }
      }
      setWhatsapp(whatsapp_number);
    }
  }, [geolocation]);

  const value = {
    whatsapp_number: Whatsapp,
  };

  return (
    <WhatsappContext.Provider value={value}>
      {children}
    </WhatsappContext.Provider>
  );
}

export function useWhatsapp() {
  const context = useContext(WhatsappContext);
  if (!context) {
    throw new Error("useData debe ser utilizado dentro de un DataProvider");
  }
  return context;
}
