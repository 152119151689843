const yearDiff = (dt1, dt2) => {
  var diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60 * 60 * 24;
  return Math.abs(diff / 365.25);
};

function diff_years(dt2, dt1) {
  dt2.setHours(0, 0, 0, 0);
  dt1.setHours(0, 0, 0, 0);
  var diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60 * 60 * 24;
  return Math.abs(diff / 365.25);
}

export const validateDate = (value) => {
  const validation =
    /^(0?[1-9]|[12][0-9]|3[01])[\/](0?[1-9]|1[012])[\/\-]\d{4}$/.test(value);
  return validation;
};

export const validateAge = (birthdate, maxAge, ERROR_MESSAGES) => {
  try {
    const [day, month, year] = birthdate.split("/").map(Number);

    if (isNaN(day) || isNaN(month) || isNaN(year)) {
      throw new Error(ERROR_MESSAGES.INCORRECT_DATE_FORMAT);
    }

    if (month < 1 || month > 12) {
      throw new Error(ERROR_MESSAGES.INVALID_MONTH);
    }

    const daysInMonth = new Date(year, month, 0).getDate();
    if (day < 1 || day > daysInMonth) {
      throw new Error(ERROR_MESSAGES.INVALID_DAY);
    }

    // const birthdateObj = new Date(year, month - 1, day);
    const birthdateObj = new Date(year, month - 1, day);

    if (isNaN(birthdateObj.getTime())) {
      throw new Error(ERROR_MESSAGES.INVALID_BIRTHDATE);
    }

    const currentDate = new Date();
    const age = currentDate.getFullYear() - birthdateObj.getFullYear();

    if (currentDate < birthdateObj || age < 0) {
      throw new Error(ERROR_MESSAGES.INVALID_BIRTHDATE);
    }

    return diff_years(birthdateObj, currentDate) < maxAge
      ? { status: true, message: "" }
      : { status: false, message: ERROR_MESSAGES.INVALID_AGE };
  } catch (error) {
    return {
      status: false,
      message: error.message,
    };
  }
};

export const validateDocs = (value, pax, total_pax, getValues, br) => {
  try {
    for (let index = 0; index < total_pax; index++) {
      const doc = getValues(`doc_number_${index}`);
      if (value === doc && pax !== index && value !== "") {
        return {
          status: false,
          message: "form.error.duplicatedDocument",
        };
      }
    }

    const docType = getValues(`doc_type_${pax}`);


    if (docType === "1") {
      const regExpValidation = {
        cpf: /^([-\.\s]?(\d{3})){3}[-\.\s]?(\d{2})$/,
        passport: /^[a-zA-Z0-9.-]+$/,
      };

      let validation = br
        ? regExpValidation.cpf.test(value)
        : regExpValidation.passport.test(value);

      if (!validation) {
        return {
          status: validation,
          message: br
            ? "form.error.invalidCPF"
            : "form.error.onlyLetterAndNumbers",
        };
      }
    }

    return {
      status: true,
      message: "",
    };
  } catch (error) {
    return {
      status: false,
      message: error.message,
    };
  }
};

export const validateExpirationDate = (date, ERROR_MESSAGE) => {
  try {
    const currentDate = new Date();
    const [month, year] = date.split("/");

    if (year && month) {
      const expiration = new Date(`20${year}`, month - 1);

      if (month < 1 || month > 12) {
        throw new Error(ERROR_MESSAGE);
      }

      if (expiration < currentDate) {
        throw new Error(ERROR_MESSAGE);
      }
    }
    return {
      status: true,
      message: null,
    };
  } catch (error) {
    return {
      status: false,
      message: error.message,
    };
  }
};
