import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormData } from "../../context/formContext";

import {
  emitPOSTController,
  payPOSTController,
} from "../../dataController/dataController";
import { removeBeneficiaryKeys } from "../../helpers/sanitize";
import { useActionButton } from "../../context/actionButtonContext";

const infoAlertStyle = {
  borderRadius: "10px",
  backgroundColor: "#E5E3FC",
  border: "solid 1px #3C2CE8",
  color: "#0E0849",
  fontWeight: "normal",
  boxShadow: "none",
};
const DlocalMercadoPago = () => {

  const { t } = useTranslation();
  const { parsedData } = useFormData();
  const [reserveData, setReserveData] = useState(parsedData);

  const { setActionButtonEnabled, setActionButtonAvailable } = useActionButton();
  setActionButtonAvailable(false);

  const handleSubmissionError = (error) => {
    console.error("Error during submission:", error);
  };

  useEffect(() => {

    setActionButtonAvailable(false);

    if (parsedData) {

      const fetchData = async () => {
        try {
          const result = await emitPOSTController.fetchData({
            body: parsedData,
          });

          const reserve = result?.data?.reserve;
          const status = result?.data?.status;

          if (status) {
            setReserveData((prev) => ({
              ...prev,
              ...{
                reserve_id: reserve.new_id,
                reserve_status: status,
              },
            }));
          }
        } catch (error) {
          handleSubmissionError(error);
        } finally {
          console.info("Submission done");
        }
      };
      fetchData();
    }
  }, [parsedData]);

  useEffect(() => {
    if (reserveData.reserve_status) {
      const fetchData = async () => {
        try {


          const payload = removeBeneficiaryKeys(reserveData);

          const payer_name = parsedData["beneficiary[name][0]"] + ' ' + parsedData["beneficiary[lastname][0]"];
          const payer_email = parsedData["beneficiary[email][0]"];
          const payer_document = parsedData["beneficiary[doc_number][0]"];

          const country_code = localStorage.getItem("country_code");
          const currency_code = localStorage.getItem("currency_symbol");

          const payData = {
            ...payload,
            ...{
              method: "Dlocal",
              mp: "true",
              payment_method: "Dlocal",
              payer_email: payer_email,
              payer_name: payer_name,
              payer_document: payer_document,
              country_code: country_code,
              currency_code: currency_code,
            }
          }
          const result = await payPOSTController.fetchData({
            body: payData,
          });

          if (result?.data?.status) {

            console.log('result ----> ', result);
            window.location.href = result?.data.redirect_url;

          } else {
          }
        } catch (error) {
          handleSubmissionError(error);
        } finally {
          console.info("Submission done");
        }
      };
      fetchData();
    }
  }, [reserveData]);

  return null; // No renderiza ningún elemento visual.
};

export default DlocalMercadoPago;