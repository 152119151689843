import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "./locationContext";
import { useTranslation } from "react-i18next";

const WhatsappContext = createContext({
  data: null,
});

export function WhatsappProvider({ children }) {
  const { geolocation } = useLocation();
  const [Whatsapp, setWhatsapp] = useState("");
  const { i18n } = useTranslation();

  const chatCenter = (country_id) => {
    return [52, 135, 158, 199, 157, 6, 161, 155, 165].includes(
      parseInt(country_id)
    );
  };

  const initWhatsApp = (today_day, today_time, country_id, header_whatsapp) => {
    try {
      const url = "https://assistcdn.s3.us-west-1.amazonaws.com/assets/site/js/chat_support.json";
      const method = "GET";

      fetch(url, {
        method: method,
      })
        .then((response) => response.json())
        .then((response) => {
          const condition = response;
          let whatsapp_number = "";

          let rules = "";
          const total = Object.keys(condition).length;
          let counter = 1;

          Object.keys(condition).forEach((idx) => {
            rules += `(${today_day} === ${idx}`;
            if (condition[idx]) {
              let time = "";
              const operator = condition[idx].match(/[<>]=?|===/gi);

              time =
                condition[idx].length > 0
                  ? condition[idx].slice(
                      operator[0]?.length,
                      condition[idx].length
                    )
                  : null;

              rules += ` && '${today_time}' ${operator} '${time}')`;
            } else {
              rules += ")";
            }
            if (counter < total) rules += " || ";
            counter++;
          });

          let _rules = `(${rules})`;

          if (!_rules) {
            _rules = `(${today_day} === "6") || (${today_day} === "7" && "${today_hour}" < "22:01")`;
          }

          if (eval(_rules)) {
            whatsapp_number = "5491122647575";
          } else {
            whatsapp_number = chatCenter(country_id)
              ? "5491122647575"
              : header_whatsapp.substring(1);
            if (
              country_id === 155 ||
              country_id === 201 ||
              i18n.language === "pt-BR"
            ) {
              whatsapp_number = "5548988523408";
            }
          }
          setWhatsapp(whatsapp_number);
        });
    } catch (error) {
      throw new Error("Something went wrong");
    }
  };

  useEffect(() => {
    if (geolocation) {
      let today_day = geolocation?.today_day;
      let today_hour = geolocation?.today_hour;
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has("today_day") || urlParams.has("today_hour")) {
        if (urlParams.get("today_day")) {
          today_day = urlParams.get("today_day").toString();
        }
        if (urlParams.get("today_hour")) {
          today_hour = urlParams.get("today_hour").toString();
        }
      }
      initWhatsApp(today_day, today_hour, geolocation?.country_id, geolocation?.header_whatsapp);
    }
  }, [geolocation]);

  const value = {
    whatsapp_number: Whatsapp,
  };

  return (
    <WhatsappContext.Provider value={value}>
      {children}
    </WhatsappContext.Provider>
  );
}

export function useWhatsapp() {
  const context = useContext(WhatsappContext);
  if (!context) {
    throw new Error("useData debe ser utilizado dentro de un DataProvider");
  }
  return context;
}
