import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    "pt-BR": {
      translations: {
        index: {
          home: "Home",
          back: "Voltar",
          viewPurchaseDetail: "Ver Detalhe da compra",
          personalData: "Dados pessoais",
          billingData: "Upgrades",
          paymentMethods: "Meios de Pagamento",
          payment: "Pagamento",
          termsAndConditions: "Eu aceito os <0>termos e condições</0>",
          continue: "Continuar",
          attentionMessage:
            "Observe que você não poderá continuar com a compra se ela tiver sido realizada na viagem ou no destino. Os planos oferecidos estão disponíveis apenas para passageiros brasileiros que compram no Brasil. Se precisar comprar durante sua viagem ou destino, entre em contato conosco.",
          here: "aqui",
          missingInformation: "*falta alguma informação",
          personalDataStepTitle: "Dados pessoais",
          billingDataStepTitle: "Upgrades",
          paymentMethodsStepTitle: "Meios de pagamento",
          paymentStepTitle: "Pagamento",
          discountForm: "Tem algum código de desconto?",
          discountAnotherDiscode: "Você tem outro código de desconto?",
          discountFormButton: "Aplicar",
          pay: "Pagar",
          finish: "Finalizar",
          whatsappSupport:
            "Não consegue concluir sua compra? Escreva para nós, ajudamos você!",
          whatsappSupportMessage:
            "Olá, preciso de ajuda para pagar minha reserva número ",
        },
        ageException: {
          backToCheckout: "Voltar",
          buttonChat: "Converse com o consultor",
          links: {
            newQuotation: "Veja nova cotação",
            reQuote: "Cotar novamente",
            continue: "Continue comprando da mesma maneira",
          },
          65: {
            1: {
              title:
                "Identificamos que você completará 65 anos durante a viagem",
              dropLine:
                "Para viagens de 90 a 365 dias, a cobertura tem limite de idade de 64 anos. Se continuar a compra, tenha em atenção que a proteção termina um dia antes do seu 65º aniversário. Após essa data, a validade do serviço expira.",
              subTitle: "Que posso fazer?",
              content:
                "Recomendamos que você faça o contrato por no máximo 89 dias. Quando você estiver em viagem, poderá solicitar autorização para renovar e estender sua cobertura antes que ela termine, pelo número de dias adicionais necessários. Dessa forma você terá cobertura além do seu aniversário. Clique abaixo para ver a nova cotação e continuar comprando. Você também pode conversar com um consultor",
            },
            2: {
              title:
                "Identificamos que {{pax_fullname}} completará 65 anos enquanto viaja",
              dropLine:
                "Para viagens de 90 a 365 dias, a cobertura tem limite de idade de 64 anos. Se continuar a compra, tenha em atenção que a proteção termina um dia antes do seu 65º aniversário. Após essa data, a validade do serviço expira.",
              subTitle: "Que posso fazer?",
              content:
                "Recomendamos que você faça orçamentos separados. Para o(s) passageiro(s) que fará aniversário durante a estadia, reserva por no máximo 89 dias. Uma vez no seu destino, você pode solicitar autorização para renovar e estender sua cobertura antes que ela termine, pelo número de dias adicionais necessários. Clique abaixo para ver a nova cotação e continuar comprando. Você também pode conversar com um consultor",
            },
          },
          69: {
            1: {
              title: "Identificamos que você tem mais de 69 anos.",
              dropLine:
                "Nos planos Multitrip a cobertura tem limite de idade de 69 anos. Isso significa que você não pode continuar com a compra deste plano.",
              subTitle: "Que posso fazer?",
              content:
                "Recomendamos que escolha outro dos nossos produtos disponíveis.",
            },
            2: {
              title:
                "Identificamos que o passageiro {{pax_fullname}} tem mais de 69 anos.",
              dropLine:
                "Nos planos Multitrip a cobertura tem limite de idade de 69 anos. Isso significa que você não pode continuar com a compra deste plano.",
              subTitle: "Que posso fazer?",
              content:
                "Recomendamos que escolha outro dos nossos produtos disponíveis.",
            },
          },
          70: {
            1: {
              title:
                "Identificamos que você completará 70 anos durante o período de cobertura.",
              dropLine:
                "Nos planos Multitrip a cobertura tem limite de idade de 69 anos. Isso significa que você não pode continuar com a compra deste plano.",
              subTitle: "Que posso fazer?",
              content:
                "Recomendamos que escolha outro dos nossos produtos disponíveis.",
            },
            2: {
              title:
                "Identificamos que o passageiro {{pax_fullname}} completará 70 anos durante o período de cobertura.",
              dropLine:
                "Nos planos Multitrip a cobertura tem limite de idade de 69 anos. Isso significa que você não pode continuar com a compra deste plano.",
              subTitle: "Que posso fazer?",
              content:
                "Recomendamos que escolha outro dos nossos produtos disponíveis.",
            },
          },
          86: {
            1: {
              title:
                "Identificamos que você completará 86 anos durante a viagem",
              subTitle: "Que posso fazer?",
              content:
                "O limite de idade de todos os nossos produtos é de até 85 anos. Portanto, se você continuar com a compra, quando o passageiro completar 86 anos, a validade do serviço expirará. Recomendamos que você faça orçamentos separados.",
            },
            2: {
              title:
                "Identificamos que {{pax_fullname}} completará 86 anos enquanto viaja",
              subTitle: "Que posso fazer?",
              content:
                "O limite de idade de todos os nossos produtos é de até 85 anos. Portanto, se você continuar com a compra, quando o passageiro completar 86 anos, a validade do serviço expirará. Recomendamos que você faça orçamentos separados.",
            },
          },
        },
        customerDetail: {
          title: "Dados Pessoais",
        },
        purchaseDetail: {
          title: "Detalhe da compra",
          assistenceForPeople: "Assistência para {{count}} pessoa/s",
          destiny: "Destino {{destiny}}",
          from: "De {{from}}",
          to: "Até {{to}}",
          purchaseDetail: "Resumo de compra",
          value: "Plano {{plan_name}}",
          basePrice: "{{currency}} {{base_price}}",
          totalPriceBack: "{{currency}} {{total_price_back}}",
          priceWithDiscountCode: "Código de desconto {{percent}}% off",
          priceWithDiscountCodeDual: "Código de desconto {{percent}}% off",
          discountPrice: "-{{currency}} {{discount_price}}",
          valueWithPercentOff: "Desconto {{percent}}% off",
          total: "Total",
          totalPrice: "{{currency}} {{total_price}}",
          upgradePrice: "{{currency}} {{upgrade_price}}",
          destinations: {
            10: "Europa",
            5: "América do Sul e Caribe",
            16: "América do Norte",
            9: "Ásia",
            2: "Oceania",
            6: "África",
            17: "Vários destinos",
          },
        },
        steps: {
          personalData: {
            title: "Informações pessoais",
            passenger: "Passageiro",
            whereToSendPolicy: "Para onde você quer que enviemos sua apólice?",
            emergencyContact: "Contato de emergência",
            contactData: "Dados de contato",
            WhyDoWeAskForThis: "Por que pedimos isso?",
          },
          billingData: {
            title: "Upgrades",
            buyerDetails: "Dados do comprador",
            buyerAddress: "Endereco",
            wantToAddExtra: "Você quer adicionar algo extra à sua compra?",
            passenger: "Passageiro",
          },
          paymentMethods: {
            title: "Como você prefere pagar?",
            transferMessage:
              "Aproveite 10% de desconto pagando com transferência!",
            methods: {
              creditOrDebitCard: "Cartão de crédito ou débito",
              creditCard: "Cartão de crédito",
              debitCard: "Cartão de debito",
              Pix: "Pix",
              transfer: "Transferência bancária",
              mp: "Mercado Pago",
              rebill: {
                transfer: "Transferencia bancaria",
                cash: "Efectivo",
                btnPay: "Realizar Pago",
                title:
                  "Información importante para pagos mediante {{transaction_type}}",
                alert:
                  "Recuerda <strong>enviarnos el comprobante de pago {{transaction}}</strong> a: <a href='mailto:consultas@assist-365.com'>consultas@assist-365.com para que podamos validarlo, indicando el <strong>número de pasaporte / documento del pasajero.</strong> Una vez enviado el comprobante, recibirás tu Voucher en un plazo máximo de 72hs hábiles. Gracias!",
                titleEmail: "Te eviaremos esta información a:",
              },
            },
          },
          payment: {
            title: {
              creditCard: "Selecionar cartão de crédito",
              debitCard: "Selecionar cartão de débito",
              Pix: "Pix copia e cola",
              pixQr: "Código QR",
              deposit:
                "Deixamos os nossos dados bancários para que você possa finalizar sua compra:",
            },
            transferDataViaEmail:
              "Ou, se preferir, você pode receber os dados bancários por e-mail",
            alert:
              "Lembre-se de nos enviar o comprovante de transferência para consultas@assist-365.com, para que possamos validá-lo, indicando o número de RG ou passaporte do passageiro. Após o envio do comprovante, você receberá seu Voucher em um prazo máximo de 72 horas úteis. Obrigado!",

            cardDataFormTitle:
              "Complete com seus dados para realizar o pagamento",
            pixCopyCode:
              "Copie o código e cole no aplicativo do seu banco. Lembre-se, ñao se trata de um boleto bancario.",
            copyButtonLabel: "Copiar código",
            pixInfoText:
              "Após o pagamento na Área Pix do seu banco, você receberá sua apólice no seu e-mail.",
              pixCpfError: "Ocorreu um erro, por favor verifique seu CPF",
            bankDetailsForCompletion:
              "Deixamos nossos dados bancários para que você possa finalizar sua compra:",
            emailOptionForBankDetails:
              "Ou então, você pode receber os dados bancários por e-mail",
            transferVoucherNotice:
              "Lembre-se de nos enviar o comprovante de transferência para: consultas@assist-365.com para que possamos validá-lo, indicando o número do RG ou passaporte do passageiro. Uma vez enviado o comprovante, você receberá seu Voucher em um prazo máximo de 72 horas úteis. Obrigado!",
          },
        },
        form: {
          label: {
            email: "E-MAIL",
            name: "NOME",
            lastName: "SOBRENOME",
            nameAndLastName: "NOME E SOBRENOME",
            countryResidence: "PAÍS DE RESIDÊNCIA",
            countryResidenceNoOptions: "Nenhuma opção disponíve",
            documentType: "TIPO DE DOCUMENTO",
            documentNumber: "NÚMERO DO DOCUMENTO",
            birthdate: "DATA DE NASCIMENTO",
            cpf: "CPF",
            phone: "TELEFONE",
            cep: "CEP",
            neighborhood: "BAIRRO",
            city: "CIDADE",
            state: "ESTADO",
            address: "ENDEREÇO",
            number: "NÚMERO",
            discountCode: "CÓDIGO DE DESCONTO",
            cardNumber: "NÚMERO DO CARTÃO",
            expiration: "VENCIMENTO",
            securityCode: "CÓD. SEGURANÇA",
            cardholderDocument: "DOCUMENTO DO TITULAR DO CARTÃO",
            cardholder: "TITULAR DO CARTÃO",
            selectDues: "Insira o número de parcelas",
            dues: "PARCELAS",
          },
          error: {
            required: "Esses dados são obrigatórios",
            invalidEmail: "E-mail inválido",
            invalidDateFormat: "Formato de data inválido",
            invalidMonth: "Mês inválido",
            invalidDay: "Dia inválido",
            invalidBirthdate: "Data de nascimento inválida",
            invalidAge: "Idade não válida",
            invalidCEP: "CEP inválido",
            invalidCPF: "CPF inválido",
            duplicatedDocument: "Documento duplicado",
            onlyLetterAndNumbers: "Apenas letras e número",
            cepNotFound: "CEP não encontrado",
            invalidDiscountCode: "Código de desconto inválido",
            successDiscountCode: "Código de desconto aplicado",
            dlocal: {
              anErrorOccurred: "Ocorreu um erro, tente mais tarde por favor",
              insufficientAmount: "Saldo insuficiente",
              notApproved: "Seu pagamento não foi aprovado, verifique com seu banco emissor.",
              addCardOwner: "Adicione o nome do titular do cartão de crédito",
              addDocOwner: "Adicione o CPF do titular do cartão de crédito",
              blockedCard: "Seu cartão de crédito está desabilitado",
            },
            prisma: [
              { type: "empty_card_number", msg: "Número do cartão requerido" },
              {
                type: "empty_card_holder_name",
                msg: "Titular do cartão requerido",
              },
              {
                type: "nan_card_number",
                msg: "Caractere inválido, apenas números são permitidos",
              },
              {
                type: "invalid_card_number",
                msg: "Número do cartão inválido",
              },
              {
                type: "invalid_expiry_date",
                msg: "Data de validade inválida",
              },
              { type: "empty_token", msg: "Token inválido" },
              { type: "invalid_param", msg: "Parâmetros inválidos" },
              { type: "card_expiration_year", msg: "Ano inválido" },
              { type: "card_expiration_month", msg: "Mês inválido" },
            ],
          },
          info: {
            cpf: "*No caso de menores sem CPF, coloque o CPF da mãe, pai ou responsável",
          },
          button: {
            receiveDataByEmail: "Receber dados por e-mail",
            viewTransferData: "Ver dados bancários",
          },
          placeholder: {
            number: "Número",
            address: "Digite seu endereço",
            example: "exemplo@gmail.com",
            cardNumber: "Digite o número do cartão",
            expiration: "MM/AA",
            securityCode: "CVV",
            cardholderDocument: "Exemplo: 30154775",
            cardholder: "Como aparece no cartão",
          },
          options: {
            docType: {
              passport: "Passaporte",
              document: "CPF",
            },
            prismaCreditCards: [
              { key: "Visa", value: "1" },
              { key: "American Express", value: "65" },
              { key: "MasterCard", value: "104" },
              { key: "Cabal", value: "63" },
              { key: "Tarjeta Naranja", value: "24" },
            ],
            prismaDebitCards: [
              { key: "Visa Débito", value: "31" },
              { key: "Maestro", value: "106" },
              { key: "MasterCard Débito", value: "105" },
              { key: "Cabal Débito", value: "108" },
            ],
          },
        },
        congrats: {
          transfer: {
            form: {
              title: "Enviamos o link de pagamento para o seu email",
              checkSpam: "Lembre-se de verificar o Spam",
              otherOptionToPay:
                "Se a sua viagem começa nas próximas 72 horas, recomendamos escolher outro método de pagamento.",
              notReceiveIt: "Não o recebeu?",
              helpLink: "Reenviar",
            },
            normal: {
              title: "Obrigado por nos escolher!",
              checkSpam: "Lembre-se de verificar o Spam",
              otherOptionToPay:
                "Se a sua viagem começa nas próximas 72 horas, recomendamos escolher outro método de pagamento.",
              notReceiveIt: "Ainda não o recebeu?",
              needHelp: "Precisa de ajuda?",
              helpLink: "Contactar consultor",
              rememberSendVoucher:
                "Uma vez que você faça a transferência e nos envie o comprovante, receberá seu voucher em seu e-mail.",
              coverageForYourNextTrip:
                "Você já tem sua cobertura para sua próxima viagem",
              voucherDownload: "Baixar Voucher",
              downloadYourVoucher: "Baixe seus vouchers",
              clientDownloadVoucher: "Baixar Voucher de {{client}}",
            },
          },
        },
        upgrades: {
          technology: {
            name: "Tecnologia protegida",
            title: "Tecnologia protegida",
            description: "Para seus dispositivos tecnológicos",
            note: "USD 2000 Tecnologia protegida: câmeras fotográficas, filmadoras, smartphones, tablets e computadores",
          },
          pregnant: {
            name: "Gravidez",
            title: "Gravidez",
            description: "Para futuras mamães",
            note: "USD 10.000 Até 32 semanas de gestação - De 19 a 45 anos",
          },
          luggage: {
            name: "Danos na bagagem",
            title: "Danos na bagagem",
            description: "Por danos na sua bagagem",
            note: "USD 1.000 Em caso de danos externos à bagagem.",
          },
          pet: {
            name: "Cobertura animais de estimação",
            title: "Assistência para animais de estimação",
            description: "Para a saúde de seu cão ou gato",
            note: "USD 5.000 Para acidentes ou enfermidades não pré-existentes de seu animal de estimação.",
          },
        },
      },
    },
    "es-AR": {
      translations: {
        index: {
          back: "Volver",
          home: "Home",
          viewPurchaseDetail: "Ver Detalle de Compra",
          personalData: "Datos personales",
          billingData: "Datos de facturación",
          paymentMethods: "Métodos de pago",
          payment: "Pago",
          termsAndConditions: "Acepto los <0> términos y condiciones </0>",
          continue: "Continuar",
          finish: "Finalizar",
          pay: "Pagar",
          whatsappSupport:
            "¿No puedes finalizar tu compra? ¡Escríbenos, te ayudamos!",
          whatsappSupportMessage:
            "Hola, necesito ayuda para pagar mi reserva número ",
          attentionMessage:
            "Tenga en cuenta que no podrá continuar con la compra si se realizó en el viaje o destino. Los planes ofrecidos están disponibles solo para pasajeros brasileños que compran en Brasil. Si necesita comprar durante su viaje o destino, contáctenos.",
          here: "aquí",
          missingInformation: "*falta información",
          personalDataStepTitle: "Datos personales",
          billingDataStepTitle: "Adicionales",
          paymentMethodsStepTitle: "Medios de pago",
          paymentStepTitle: "Pago",
          discountForm: "¿Tienes algún código de descuento?",
          discountAnotherDiscode: "¿Tienes otro código de descuento?",
          discountFormButton: "Aplicar",
        },
        ageException: {
          backToCheckout: "Volver al checkout",
          buttonChat: "Chatear con asesor",
          links: {
            newQuotation: "Ver nueva cotización",
            reQuote: "Volver a cotizar",
            continue: "Continuar compra de igual manera",
          },
          65: {
            1: {
              title: "Identificamos que cumplirás 65 años estando en viaje",
              dropLine:
                "Para viajes de 90 a 365 días, la cobertura tiene un límite de edad de 64 años. Si continúas la compra, ten en cuenta que la protección termina el día antes de tu cumpleaños 65. Después de esa fecha, la validez del servicio expira",
              subTitle: "¿Qué puedo hacer?",
              content:
                "Te recomendamos que realices la contratación por un máximo de 89 días.Cuando estés en tu viaje, podrás solicitar la autorización para renovar y extender tu cobertura antes de que termine, por la cantidad de días adicionales que precises. De esta forma si tendrás cobertura más allá de tu fecha de cumpleaños.Haz clic debajo para ver la nueva cotización y continuar la compra. También puedes chatear con un asesor",
            },
            2: {
              title:
                "Identificamos que {{pax_fullname}} cumplirá los 65 años estando en viaje",
              dropLine:
                "Para viajes de 90 a 365 días, la cobertura tiene un límite de edad de 64 años. Si continúas la compra, ten en cuenta que la protección termina el día antes del cumpleaños 65. Después de esa fecha, la validez del servicio expirará.",
              subTitle: "¿Qué puedo hacer?",
              content:
                "Te recomendamos que realices cotizaciones separadas. Para el(los) pasajero(s) que cumplirá años durante la estadía, realiza la contratación por un máximo de 89 días. Una vez en destino, podrás solicitar la autorización para renovar y extender tu cobertura antes de que termine, por la cantidad de días adicionales que precises. Haz clic debajo para ver la nueva cotización y continuar la compra. También puedes chatear con un asesor",
            },
          },
          69: {
            1: {
              title: "Identificamos que tienes más de 69 años.",
              dropLine:
                " Para los planes Multitrip, la cobertura tiene un límite de edad de 69 años. Es decir que no puedes continuar con la compra de este plan.",
              subTitle: "¿Qué puedo hacer?",
              content:
                "Te recomendamos que elijas otro de nuestros productos disponibles.",
            },
            2: {
              title:
                "Identificamos que el pasajero {{pax_fullname}} tiene más de 69 años. ",
              dropLine:
                " Para los planes Multitrip, la cobertura tiene un límite de edad de 69 años. Es decir que no puedes continuar con la compra de este plan.",
              subTitle: "¿Qué puedo hacer?",
              content:
                "Te recomendamos que elijas otro de nuestros productos disponibles.",
            },
          },
          70: {
            1: {
              title:
                "Identificamos que cumplirás 70 años durante el periodo de la cobertura.",
              dropLine:
                " Para los planes Multitrip, la cobertura tiene un límite de edad de 69 años. Es decir que no puedes continuar con la compra de este plan.",
              subTitle: "¿Qué puedo hacer?",
              content:
                "Te recomendamos que elijas otro de nuestros productos disponibles.",
            },
            2: {
              title:
                "Identificamos que el pasajero {{pax_fullname}} cumplirá 70 durante el periodo de cobertura",
              dropLine:
                " Para los planes Multitrip, la cobertura tiene un límite de edad de 69 años. Es decir que no puedes continuar con la compra de este plan.",
              subTitle: "¿Qué puedo hacer?",
              content:
                "Te recomendamos que elijas otro de nuestros productos disponibles.",
            },
          },
          86: {
            1: {
              title: "Identificamos que cumplirás 86 años estando en viaje",
              subTitle: "¿Qué puedo hacer?",
              content:
                "El límite de edad de todos nuestros productos es de hasta 85 años. Por lo tanto, si continúas con la compra, tendrás cobertura solo hasta el día previo a tu cumpleaños. Una vez que cumplas los 86 años, la validez del servicio habrá caducado. Puedes volver a cotizar hasta el día anterior a la fecha de tu cumpleaños.",
            },
            2: {
              title:
                "Identificamos que el pasajero {{pax_fullname}} cumplirá 86 años estando en viaje",
              subTitle: "¿Qué puedo hacer?",
              content:
                "El límite de edad de todos nuestros productos es de hasta 85 años. Por lo tanto, si continúas con la compra, una vez que el pasajero cumpla los 86 años, caducará la validez del servicio. Te recomendamos que realices cotizaciones por separado.",
            },
          },
        },
        customerDetail: {
          title: "Datos Personales",
        },
        purchaseDetail: {
          title: "Resumen de compra",
          assistenceForPeople: "Asistencia para {{count}} persona/s",
          destiny: "Destino {{destiny}}",
          from: "Desde el {{from}}",
          to: "Hasta el {{to}}",
          purchaseDetail: "Detalle de la compra",
          value: "Plan {{plan_name}}",
          basePrice: "${{base_price}} {{currency}}",
          totalPriceBack: "${{total_price_back}} {{currency}}",
          priceWithDiscountCode: "Código de descuento {{percent}}% off",
          priceWithDiscountCodeDual: "Código de descuento {{percent}}% off",
          discountPrice: "-${{discount_price}} {{currency}}",
          valueWithPercentOff: "Descuento {{percent}}% off",
          total: "Total",
          totalPrice: "${{total_price}} {{currency}}",
          upgradePrice: "${{upgrade_price}} {{currency}}",
          destinations: {
            10: "Europa",
            5: "Sudamérica y el Caribe",
            16: "Norteamérica",
            9: "Asia",
            2: "Oceanía",
            6: "África",
            17: "Varios destinos",
          },
        },
        steps: {
          personalData: {
            title: "Información personal",
            passenger: "Pasajero",
            whereToSendPolicy: "¿A dónde quieres que enviemos tu póliza?",
            emergencyContact: "Contacto de emergencia",
            contactData: "Datos de contacto",
            WhyDoWeAskForThis: "¿Por qué pedimos esto?",
          },
          billingData: {
            title: "¿Quieres agregar algún adicional a tu compra?",
            buyerDetails: "Datos del comprador",
            buyerAddress: "Dirección",
            passenger: "Pasajero",
          },
          paymentMethods: {
            title: "¿Cómo quieres pagar?",
            transferMessage: "¡Aprovechá 10% off pagando con transferencia!",
            methods: {
              creditOrDebitCard: "Tarjeta de crédito o débito",
              creditCard: "Tarjeta de crédito",
              debitCard: "Tarjeta de débito",
              transfer: "Transferencia bancaria",
              mp: "Mercado Pago",
              rebill: {
                transfer: "Transferencia bancaria",
                cash: "Efectivo",
                btnPay: "Realizar Pago",
                title:
                  "Información importante para pagos mediante {{transaction_type}}",
                alert:
                  "Recuerda <strong>enviarnos el comprobante de pago {{transaction}}</strong> a: <a href='mailto:consultas@assist-365.com'>consultas@assist-365.com para que podamos validarlo, indicando el <strong>número de pasaporte / documento del pasajero.</strong> Una vez enviado el comprobante, recibirás tu Voucher en un plazo máximo de 72hs hábiles. Gracias!",
                titleEmail: "Te eviaremos esta información a:",
              },
            },
          },
          payment: {
            title: {
              creditCard: "Selecciona tarjeta",
              debitCard: "Selecciona tarjeta",
              deposit:
                "Te dejamos nuestros datos bancarios para que puedas finalizar tu compra:",
            },
            cardDataFormTitle: "Ingresa los datos de la tarjeta",
            transferDataViaEmail:
              "O sino, puedes recibir los datos bancarios por mail",
            alert:
              "Recuerda enviarnos el comprobante de transferencia a: consultas@assist-365.com para que podamos validarlo, indicando el número de DNI o pasaporte del pasajero. Una vez enviado el comprobante, recibirás tu Voucher en un plazo máximo de 72hs hábiles. Gracias!",
          },
        },
        form: {
          label: {
            email: "EMAIL",
            name: "NOMBRE",
            lastName: "APELLIDO",
            nameAndLastName: "NOMBRE Y APELLIDO",
            countryResidence: "PAÍS DE RESIDENCIA",
            countryResidenceNoOptions: "No hay opciones disponibles",
            documentType: "TIPO DE DOCUMENTO",
            documentNumber: "NÚMERO",
            birthdate: "FECHA DE NACIMIENTO",
            cpf: "CPF",
            phone: "TELÉFONO",
            cep: "CÓDIGO POSTAL",
            neighborhood: "BARRIO",
            city: "CIUDAD",
            state: "ESTADO",
            address: "DIRECCIÓN",
            number: "NÚMERO",
            discountCode: "CÓDIGO DE DESCUENTO",
            cardNumber: "NÚMERO DE TARJETA",
            expiration: "VENCIMIENTO",
            securityCode: "CÓD DE SEGURIDAD",
            cardholderDocument: "DOCUMENTO DEL TITULAR DE LA TARJETA",
            cardholder: "TITULAR DE LA TARJETA",
            selectDues: "Ingrese número de cuotas",
            dues: "CUOTAS",
          },
          error: {
            required: "Esta información es obligatoria",
            invalidEmail: "Correo electrónico inválido",
            invalidEmailCloned: "Correo electrónico ya registrado",
            invalidDateFormat: "Formato de fecha incorrecto",
            invalidMonth: "Mes inválido",
            invalidDay: "Día inválido",
            invalidBirthdate: "Fecha de nacimiento no válida",
            invalidAge: "Edad no válida",
            invalidCEP: "Código postal inválido",
            invalidCPF: "CPF inválido",
            duplicatedDocument: "Documento duplicado",
            cepNotFound: "Código postal no encontrado",
            invalidDiscountCode: "Código de descuento inválido",
            onlyLetterAndNumbers: "Solo letras y números",
            successDiscountCode: "Código de descuento aplicado",
            dlocal: {
              anErrorOccurred: "Ocurrio un error, intente mas tarde por favor",
              insufficientAmount: "Saldo insuficiente",
              notApproved: "Su pago fue rechazado, comuníquese con su banco emisor",
              addCardOwner:
                "Ingrese el nombre del titular de la tarjeta de crédito",
              addDocOwner:
                "Ingrese el documento del titular de la tarjeta de crédito",
              blockedCard: "Su tarjeta esta deshabilitada",
            },
            prisma: [
              { type: "empty_card_number", msg: "Número de tarjeta requerido" },
              {
                type: "empty_card_holder_name",
                msg: "Titular de la tarjeta requerido",
              },
              {
                type: "nan_card_number",
                msg: "Caracter inválido, solo números",
              },
              {
                type: "invalid_card_number",
                msg: "Número de tarjeta inválido",
              },
              {
                type: "invalid_expiry_date",
                msg: "Fecha de expiración inválida",
              },
              { type: "empty_token", msg: "Token inválido" },
              { type: "invalid_param", msg: "Parametros inválidos" },
              { type: "card_expiration_year", msg: "Año inválido" },
              { type: "card_expiration_month", msg: "Mes inválido" },
            ],
          },
          info: {
            cpf: "*En caso de menores sin CPF, coloque el CPF de la madre, padre o responsable",
          },
          placeholder: {
            number: "Número",
            address: "Ingrese su dirección",
            example: "ejemplo@gmail.com",
            cardNumber: "Ingresa número de tarjeta",
            expiration: "MM/AA",
            securityCode: "CVV",
            cardholderDocument: "Ej: 30154775",
            cardholder: "Como figura en la tarjeta",
          },
          button: {
            receiveDataByEmail: "Recibir datos por mail",
            viewTransferData: "Ver datos bancarios",
          },
          options: {
            docType: {
              passport: "Pasaporte",
              document: " DNI o Cédula de identidad ",
            },
            prismaCreditCards: [
              { key: "Visa", value: "1" },
              { key: "American Express", value: "65" },
              { key: "MasterCard", value: "104" },
              { key: "Cabal", value: "63" },
              { key: "Tarjeta Naranja", value: "24" },
            ],
            prismaDebitCards: [
              { key: "Visa Débito", value: "31" },
              { key: "Maestro", value: "106" },
              { key: "MasterCard Debit", value: "105" },
              { key: "Cabal Débito", value: "108" },
            ],
          },
        },
        congrats: {
          transfer: {
            form: {
              title: "Enviamos el link de pago a tu email",
              checkSpam: "Recuerda revisar el Spam",
              otherOptionToPay:
                "Si tu viaje inicia en las próximas 72 horas, te recomendamos elegir otro método de pago.",
              notReceiveIt: "¿No lo recibiste?",
              helpLink: "Volver a enviar",
            },
            normal: {
              title: "¡Gracias por elegirnos!",
              checkSpam: "Recuerda revisar el Spam",
              otherOptionToPay:
                "Si tu viaje inicia en las próximas 72 horas, te recomendamos elegir otro método de pago.",
              notReceiveIt: "¿Todavía no lo recibiste?",
              needHelp: "¿Necesitas ayuda?",
              helpLink: "Contactar asesor",
              rememberSendVoucher:
                "Una vez  que realices la transferencia y nos envías en comprobante, recibirás tu voucher en tu casilla de mail.",
              coverageForYourNextTrip:
                "Ya tienes tu cobertura para tu próximo viaje",
              voucherDownload: "Descargar Voucher",
              downloadYourVoucher: "Descarga tus vouchers",
              clientDownloadVoucher: "Descargar Voucher de {{client}}",
            },
          },
        },
        upgrades: {
          technology: {
            name: "Tecnología protegida",
            title: "Tecnología protegida",
            description: "Para tus dispositivos tecnológicos",
            note: "USD 2000 Tecnología protegida : cámaras de fotografía, filmadoras, teléfonos inteligentes, tablets y computadoras",
          },
          pregnant: {
            name: "Embarazo",
            title: "Embarazo",
            description: "Para futuras mamás",
            note: "USD 10.000 Hasta 32 semanas de gestación - Desde 19 a 45 años",
          },
          luggage: {
            name: "Rotura de equipaje",
            title: "Rotura de equipaje",
            description: "Para daños en tu equipaje",
            note: "USD 1.000 En caso de daños en el exterior del equipaje o violación de cerraduras",
          },
          pet: {
            name: "Cobertura para mascotas",
            title: "Asistencia médica para mascotas",
            description: "Para la salud de tus mascotas",
            note: "USD 5.000 Por accidentes o enfermedades no preexistentes de tu mascota",
          },
        },
      },
    },
    es: {
      translations: {
        index: {
          back: "Volver",
          home: "Home",
          viewPurchaseDetail: "Ver Detalle de Compra",
          personalData: "Datos personales",
          billingData: "Datos de facturación",
          paymentMethods: "Métodos de pago",
          payment: "Pago",
          termsAndConditions: "Acepto los <0> términos y condiciones </0>",
          continue: "Continuar",
          finish: "Finalizar",
          pay: "Pagar",
          whatsappSupport:
            "¿No puedes finalizar tu compra? ¡Escríbenos, te ayudamos!",
          whatsappSupportMessage:
            "Hola, necesito ayuda para pagar mi reserva número ",
          attentionMessage:
            "Tenga en cuenta que no podrá continuar con la compra si se realizó en el viaje o destino. Los planes ofrecidos están disponibles solo para pasajeros brasileños que compran en Brasil. Si necesita comprar durante su viaje o destino, contáctenos.",
          here: "aquí",
          missingInformation: "*falta información",
          personalDataStepTitle: "Datos personales",
          billingDataStepTitle: "Adicionales",
          paymentMethodsStepTitle: "Medios de pago",
          paymentStepTitle: "Pago",
          discountForm: "¿Tienes algún código de descuento?",
          discountAnotherDiscode: "¿Tienes otro código de descuento?",
          discountFormButton: "Aplicar",
        },
        ageException: {
          backToCheckout: "Volver al checkout",
          buttonChat: "Chatear con asesor",
          links: {
            newQuotation: "Ver nueva cotización",
            reQuote: "Volver a cotizar",
            continue: "Continuar compra de igual manera",
          },
          65: {
            1: {
              title: "Identificamos que cumplirás 65 años estando en viaje",
              dropLine:
                "Para viajes de 90 a 365 días, la cobertura tiene un límite de edad de 64 años. Si continúas la compra, ten en cuenta que la protección termina el día antes de tu cumpleaños 65. Después de esa fecha, la validez del servicio expira",
              subTitle: "¿Qué puedo hacer?",
              content:
                "Te recomendamos que realices la contratación por un máximo de 89 días.Cuando estés en tu viaje, podrás solicitar la autorización para renovar y extender tu cobertura antes de que termine, por la cantidad de días adicionales que precises. De esta forma si tendrás cobertura más allá de tu fecha de cumpleaños.Haz clic debajo para ver la nueva cotización y continuar la compra. También puedes chatear con un asesor",
            },
            2: {
              title:
                "Identificamos que {{pax_fullname}} cumplirá los 65 años estando en viaje",
              dropLine:
                "Para viajes de 90 a 365 días, la cobertura tiene un límite de edad de 64 años. Si continúas la compra, ten en cuenta que la protección termina el día antes del cumpleaños 65. Después de esa fecha, la validez del servicio expirará.",
              subTitle: "¿Qué puedo hacer?",
              content:
                "Te recomendamos que realices cotizaciones separadas. Para el(los) pasajero(s) que cumplirá años durante la estadía, realiza la contratación por un máximo de 89 días. Una vez en destino, podrás solicitar la autorización para renovar y extender tu cobertura antes de que termine, por la cantidad de días adicionales que precises. Haz clic debajo para ver la nueva cotización y continuar la compra. También puedes chatear con un asesor",
            },
          },
          69: {
            1: {
              title: "Identificamos que tienes más de 69 años.",
              dropLine:
                " Para los planes Multitrip, la cobertura tiene un límite de edad de 69 años. Es decir que no puedes continuar con la compra de este plan.",
              subTitle: "¿Qué puedo hacer?",
              content:
                "Te recomendamos que elijas otro de nuestros productos disponibles.",
            },
            2: {
              title:
                "Identificamos que el pasajero {{pax_fullname}} tiene más de 69 años. ",
              dropLine:
                " Para los planes Multitrip, la cobertura tiene un límite de edad de 69 años. Es decir que no puedes continuar con la compra de este plan.",
              subTitle: "¿Qué puedo hacer?",
              content:
                "Te recomendamos que elijas otro de nuestros productos disponibles.",
            },
          },
          70: {
            1: {
              title:
                "Identificamos que cumplirás 70 años durante el periodo de la cobertura.",
              dropLine:
                " Para los planes Multitrip, la cobertura tiene un límite de edad de 69 años. Es decir que no puedes continuar con la compra de este plan.",
              subTitle: "¿Qué puedo hacer?",
              content:
                "Te recomendamos que elijas otro de nuestros productos disponibles.",
            },
            2: {
              title:
                "Identificamos que el pasajero {{pax_fullname}} cumplirá 70 durante el periodo de cobertura",
              dropLine:
                " Para los planes Multitrip, la cobertura tiene un límite de edad de 69 años. Es decir que no puedes continuar con la compra de este plan.",
              subTitle: "¿Qué puedo hacer?",
              content:
                "Te recomendamos que elijas otro de nuestros productos disponibles.",
            },
          },
          86: {
            1: {
              title: "Identificamos que cumplirás 86 años estando en viaje",
              subTitle: "¿Qué puedo hacer?",
              content:
                "El límite de edad de todos nuestros productos es de hasta 85 años. Por lo tanto, si continúas con la compra, tendrás cobertura solo hasta el día previo a tu cumpleaños. Una vez que cumplas los 86 años, la validez del servicio habrá caducado. Puedes volver a cotizar hasta el día anterior a la fecha de tu cumpleaños.",
            },
            2: {
              title:
                "Identificamos que el pasajero {{pax_fullname}} cumplirá 86 años estando en viaje",
              subTitle: "¿Qué puedo hacer?",
              content:
                "El límite de edad de todos nuestros productos es de hasta 85 años. Por lo tanto, si continúas con la compra, una vez que el pasajero cumpla los 86 años, caducará la validez del servicio. Te recomendamos que realices cotizaciones por separado.",
            },
          },
        },
        customerDetail: {
          title: "Datos Personales",
        },
        purchaseDetail: {
          title: "Resumen de compra",
          assistenceForPeople: "Asistencia para {{count}} persona/s",
          destiny: "Destino {{destiny}}",
          from: "Desde el {{from}}",
          to: "Hasta el {{to}}",
          purchaseDetail: "Detalle de la compra",
          value: "Plan {{plan_name}}",
          basePrice: "${{base_price}} {{currency}}",
          totalPriceBack: "${{total_price_back}} {{currency}}",
          priceWithDiscountCode: "Código de descuento {{percent}}% off",
          priceWithDiscountCodeDual: "Código de descuento {{percent}}% off",
          discountPrice: "-${{discount_price}} {{currency}}",
          valueWithPercentOff: "Descuento {{percent}}% off",
          total: "Total",
          totalPrice: "${{total_price}} {{currency}}",
          upgradePrice: "${{upgrade_price}} {{currency}}",
          destinations: {
            10: "Europa",
            5: "Sudamérica y el Caribe",
            16: "Norteamérica",
            9: "Asia",
            2: "Oceanía",
            6: "África",
            17: "Varios destinos",
          },
        },
        steps: {
          personalData: {
            title: "Información personal",
            passenger: "Pasajero",
            whereToSendPolicy: "¿A dónde quieres que enviemos tu póliza?",
            emergencyContact: "Contacto de emergencia",
            contactData: "Datos de contacto",
            WhyDoWeAskForThis: "¿Por qué pedimos esto?",
          },
          billingData: {
            title: "¿Quieres agregar algún adicional a tu compra?",
            buyerDetails: "Datos del comprador",
            buyerAddress: "Dirección",
            passenger: "Pasajero",
          },
          paymentMethods: {
            title: "¿Cómo quieres pagar?",
            transferMessage: "¡Aprovechá 10% off pagando con transferencia!",
            methods: {
              creditOrDebitCard: "Tarjeta de crédito o débito",
              creditCard: "Tarjeta de crédito",
              debitCard: "Tarjeta de débito",
              transfer: "Transferencia bancaria",
              mp: "Mercado Pago",
              rebill: {
                transfer: "Transferencia bancaria",
                cash: "Efectivo",
                btnPay: "Realizar Pago",
                title:
                  "Información importante para pagos mediante {{transaction_type}}",
                alert:
                  "Recuerda <strong>enviarnos el comprobante de pago {{transaction}}</strong> a: <a href='mailto:consultas@assist-365.com'>consultas@assist-365.com para que podamos validarlo, indicando el <strong>número de pasaporte / documento del pasajero.</strong> Una vez enviado el comprobante, recibirás tu Voucher en un plazo máximo de 72hs hábiles. Gracias!",
                titleEmail: "Te eviaremos esta información a:",
              },
            },
          },
          payment: {
            title: {
              creditCard: "Selecciona tarjeta",
              debitCard: "Selecciona tarjeta",
              deposit:
                "Te dejamos nuestros datos bancarios para que puedas finalizar tu compra:",
            },
            cardDataFormTitle: "Ingresa los datos de la tarjeta",
            transferDataViaEmail:
              "O sino, puedes recibir los datos bancarios por mail",
            alert:
              "Recuerda enviarnos el comprobante de transferencia a: consultas@assist-365.com para que podamos validarlo, indicando el número de DNI o pasaporte del pasajero. Una vez enviado el comprobante, recibirás tu Voucher en un plazo máximo de 72hs hábiles. Gracias!",
          },
        },
        form: {
          label: {
            email: "EMAIL",
            name: "NOMBRE",
            lastName: "APELLIDO",
            nameAndLastName: "NOMBRE Y APELLIDO",
            countryResidence: "PAÍS DE RESIDENCIA",
            countryResidenceNoOptions: "No hay opciones disponibles",
            documentType: "TIPO DE DOCUMENTO",
            documentNumber: "NÚMERO",
            birthdate: "FECHA DE NACIMIENTO",
            cpf: "CPF",
            phone: "TELÉFONO",
            cep: "CÓDIGO POSTAL",
            neighborhood: "BARRIO",
            city: "CIUDAD",
            state: "ESTADO",
            address: "DIRECCIÓN",
            number: "NÚMERO",
            discountCode: "CÓDIGO DE DESCUENTO",
            cardNumber: "NÚMERO DE TARJETA",
            expiration: "VENCIMIENTO",
            securityCode: "CÓD DE SEGURIDAD",
            cardholderDocument: "DOCUMENTO DEL TITULAR DE LA TARJETA",
            cardholder: "TITULAR DE LA TARJETA",
            selectDues: "Ingrese número de cuotas",
            dues: "CUOTAS",
          },
          error: {
            required: "Esta información es obligatoria",
            invalidEmail: "Correo electrónico inválido",
            invalidDateFormat: "Formato de fecha incorrecto",
            invalidMonth: "Mes inválido",
            invalidDay: "Día inválido",
            invalidBirthdate: "Fecha de nacimiento no válida",
            invalidAge: "Edad no válida",
            invalidCEP: "Código postal inválido",
            invalidCPF: "CPF inválido",
            duplicatedDocument: "Documento duplicado",
            cepNotFound: "Código postal no encontrado",
            onlyLetterAndNumbers: "Solo letras y números",
            invalidDiscountCode: "Código de descuento inválido",
            successDiscountCode: "Código de descuento aplicado",
            dlocal: {
              anErrorOccurred: "Ocurrio un error, intente mas tarde por favor",
              insufficientAmount: "Saldo insuficiente",
              notApproved: "Su pago fue rechazado, comuníquese con su banco emisor",
              addCardOwner:
                "Ingrese el nombre del titular de la tarjeta de crédito",
              addDocOwner:
                "Ingrese el documento del titular de la tarjeta de crédito",
              blockedCard: "Su tarjeta esta deshabilitada",
            },
            prisma: [
              { type: "empty_card_number", msg: "Número de tarjeta requerido" },
              {
                type: "empty_card_holder_name",
                msg: "Titular de la tarjeta requerido",
              },
              {
                type: "nan_card_number",
                msg: "Caracter inválido, solo números",
              },
              {
                type: "invalid_card_number",
                msg: "Número de tarjeta inválido",
              },
              {
                type: "invalid_expiry_date",
                msg: "Fecha de expiración inválida",
              },
              { type: "empty_token", msg: "Token inválido" },
              { type: "invalid_param", msg: "Parametros inválidos" },
              { type: "card_expiration_year", msg: "Año inválido" },
              { type: "card_expiration_month", msg: "Mes inválido" },
            ],
          },
          info: {
            cpf: "*En caso de menores sin CPF, coloque el CPF de la madre, padre o responsable",
          },
          placeholder: {
            number: "Número",
            address: "Ingrese su dirección",
            example: "ejemplo@gmail.com",
            cardNumber: "Ingresa número de tarjeta",
            expiration: "MM/AA",
            securityCode: "CVV",
            cardholderDocument: "Ej: 30154775",
            cardholder: "Como figura en la tarjeta",
          },
          button: {
            receiveDataByEmail: "Recibir datos por mail",
            viewTransferData: "Ver datos bancarios",
          },
          options: {
            docType: {
              passport: "Pasaporte",
              document: " DNI o Cédula de identidad ",
            },
            prismaCreditCards: [
              { key: "Visa", value: "1" },
              { key: "American Express", value: "65" },
              { key: "MasterCard", value: "104" },
              { key: "Cabal", value: "63" },
              { key: "Tarjeta Naranja", value: "24" },
            ],
            prismaDebitCards: [
              { key: "Visa Débito", value: "31" },
              { key: "Maestro", value: "106" },
              { key: "MasterCard Debit", value: "105" },
              { key: "Cabal Débito", value: "108" },
            ],
          },
        },
        congrats: {
          transfer: {
            form: {
              title: "Enviamos el link de pago a tu email",
              checkSpam: "Recuerda revisar el Spam",
              otherOptionToPay:
                "Si tu viaje inicia en las próximas 72 horas, te recomendamos elegir otro método de pago.",
              notReceiveIt: "¿No lo recibiste?",
              helpLink: "Volver a enviar",
            },
            normal: {
              title: "¡Gracias por elegirnos!",
              checkSpam: "Recuerda revisar el Spam",
              otherOptionToPay:
                "Si tu viaje inicia en las próximas 72 horas, te recomendamos elegir otro método de pago.",
              notReceiveIt: "¿Todavía no lo recibiste?",
              needHelp: "¿Necesitas ayuda?",
              helpLink: "Contactar asesor",
              rememberSendVoucher:
                "Una vez  que realices la transferencia y nos envías en comprobante, recibirás tu voucher en tu casilla de mail.",
              coverageForYourNextTrip:
                "Ya tienes tu cobertura para tu próximo viaje",
              voucherDownload: "Descargar Voucher",
              downloadYourVoucher: "Descarga tus vouchers",
              clientDownloadVoucher: "Descargar Voucher de {{client}}",
            },
          },
        },
        upgrades: {
          technology: {
            title: "Tecnología protegida",
            description: "Para tus dispositivos tecnológicos",
            note: "USD 2000 Tecnología protegida : cámaras de fotografía, filmadoras, teléfonos inteligentes, tabletas y computadores",
          },
          pregnant: {
            title: "Embarazo",
            description: "Para futuras mamás",
            note: "USD 10.000 Hasta 32 semanas de gestación - Desde 19 a 45 años",
          },
          luggage: {
            title: "Rotura de equipaje",
            description: "Para daños en tu equipaje",
            note: "USD 1.000 En caso de daños en el exterior del equipaje o violación de cerraduras",
          },
          pet: {
            title: "Asistencia médica para mascotas",
            description: "Para la salud de tus mascotas",
            note: "USD 5.000 Por accidentes o enfermedades no preexistentes de tu mascota",
          },
        },
      },
    },
  },
  lng: "es-AR",
  fallbackLng: "pt-BR",
  ns: ["translations"],
  defaultNS: "translations",
});

export default i18n;
