import React, { useCallback, useEffect, useMemo, useState } from "react";
import InputMask from "react-input-mask";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import LockIcon from "@mui/icons-material/Lock";

import {
  FormGroup,
  TextField,
  MenuItem,
  Card,
  CardContent,
  Alert,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { usePaymentMethod } from "../../context/paymentMethodContext";
import { usePrisma } from "../../context/prismaContext";
import * as paymentProcessHelper from "../../helpers/paymentProcessHelper";
import { useFormData } from "../../context/formContext";
import { useCountries } from "../../context/countriesContext";
import { mapBillingData } from "../../helpers/dataMapper";
import { removeBeneficiaryKeys } from "../../helpers/sanitize";
import { useLocation } from "../../context/locationContext";
import { useQuote } from "../../context/quoteContext";
import { useActionButton } from "../../context/actionButtonContext";
import { useLoader } from "../../context/loaderContext";
import WhatsappSupport from "../WhatsappSupport/WhatsappSupport";

const PrismaCreditCardForm = ({
  control,
  Controller,
  errors,
  handleSubmit,
  formRef,
  purchaseDetail,
}) => {
  const { t } = useTranslation();
  const { isLink } = useQuote();
  const { paymentMethod } = usePaymentMethod();
  const { parsedData } = useFormData();
  const { countries } = useCountries();
  const { basePath } = useLocation();
  const [requestError, setRequestError] = useState(null);
  const { decidirInstance } = usePrisma();
  const [mask, setMask] = useState({ value: "9999-9999-9999-9999", csv: 3 });
  const [brand, setBrand] = useState(null);
  const [prismaResponse, setPrismaResponse] = useState(null);
  const [reserveData, setReserveData] = useState(parsedData);
  const [billingState, setBillingState] = useState(false);
  const [expDate, setExpDate] = useState("00/00");
  const [dues, setDues] = useState(1);
  const { actionButtonEnabled, setActionButtonEnabled } = useActionButton();

  const expDateMonth = expDate.split("/")[0];
  const expDateYear = expDate.split("/")[1];
  const { data } = useQuote();
  const { setLoader } = useLoader();

  const userCountry = countries.find(
    country => country.id === reserveData.country
  );

  const billingData = useMemo(() => {
    return reserveData?.reserve_id
      ? mapBillingData(reserveData, userCountry, "Paypal")
      : null;
  }, [reserveData]);

  const ERROR_MESSAGES = {
    form: {
      REQUIRED: t("form.error.required"),
      INCORRECT_DATE_FORMAT: t("form.error.invalidDateFormat"),
    },
  };

  const cardsList =
    paymentMethod === "decidirDebit"
      ? t("form.options.prismaDebitCards", { returnObjects: true })
      : t("form.options.prismaCreditCards", { returnObjects: true }) || [];

  const sdkResponseHandler = (status, response) => {
    if (status !== 200 && status !== 201) {
      const storedMessages = t("form.error.prisma", { returnObjects: true });
      const message = storedMessages.find(
        m => m.type === response["error"][0]["error"]["type"]
      );
      setRequestError(message?.msg);
    } else {
      setPrismaResponse(prev => ({ ...prev, ...response }));
      setRequestError(null);
    }
  };

  const onSubmit = useCallback(
    data => {
      if (data && decidirInstance) {
        decidirInstance.createToken(formRef.current, sdkResponseHandler);
      }
    },
    [decidirInstance, formRef]
  );

  const onBrand = value => {
    setBrand(value);
    switch (value) {
      case "65": {
        setMask({ value: "9999-999999-99999", csv: 4 });
        break;
      }

      default: {
        setMask({ value: "9999-9999-9999-9999", csv: 3 });
        break;
      }
    }
  };

  const emit = useCallback(async () => {
    setRequestError(null);
    setLoader(true);

    try {
      if (purchaseDetail.hasOwnProperty("discount_dual")) {
        const dual_code = purchaseDetail?.discount_dual?.code || null;
        if (dual_code) {
          parsedData["discode_dual"] = dual_code;
        }
      }
    } catch (error) {
      console.error(error);
    }

    const result = await paymentProcessHelper.emit(parsedData);

    if (result?.reserve_id) {
      setReserveData(prev => ({
        ...prev,
        ...{
          reserve_id: result?.reserve_id,
          reserve_status: result?.reserve_status,
        },
      }));
    }
  }, [parsedData]);

  const fetchBilling = useCallback(async () => {
    const result = await paymentProcessHelper.fetchBilling(billingData);
    setBillingState(result.status);
    console.info("fetchBilling done");
  }, [billingData]);

  const [prismaInstallments, setPrismaInstallments] = useState([]);

  useEffect(() => {

    const installments_12 = true;

    const total_price = purchaseDetail?.total_price ?? data?.quote.total_price;
    const currency_symbol = data?.quote.currency_symbol;
    const installments = [];

    if (installments_12) {


      const installments_values = [
        { value: 1, installment: 1 },
        { value: 3, installment: 13 },
        { value: 6, installment: 16 },
        { value: 12, installment: 7 },
      ];

      installments_values.map((v, index) => {

        let { value, installment } = v;

        let label = "";
        let installment_text = " cuota";

        if (index > 0) {
          installment_text += "s";
        }

        if (index !== 0) {
          installment_text += " (sin interés)";
        }

        label =
          value +
          installment_text +
          " de " +
          " $" +
          " " +
          (total_price / (value)).toFixed(3);

        const obj = {
          value: installment,
          label: label,
        };
        installments.push(obj);
      })
    } else {

      // const total = [1, 2, 13, 4, 5, 16];
      // Cuotas sin PLAN (13,16)
      // const total = [1, 2, 3, 4, 5, 6];

      const total = [1, 2, 13];

      for (let index = 0; index < total.length; index++) {
        let label = "";
        let plus = "";
        let installment_text = " cuota";

        if (index > 0) {
          installment_text += "s";
        }

        if (index === 0 || index === 2 || index === 5) {
          if (index === 2 || index === 5) {
            installment_text += " (sin interés)";
          }

          label =
            index +
            1 +
            installment_text +
            " de " +
            currency_symbol +
            " " +
            (total_price / (index + 1)).toFixed(3);
        } else {
          label = index + 1 + installment_text + " (*)";
        }

        const obj = {
          value: total[index],
          label: label,
        };

        installments.push(obj);
      }
    }


    const prisma_installments = installments.map((row, index) => {
      return {
        value: row.value,
        label: row.label,
      };
    });

    setPrismaInstallments(prisma_installments);
  }, []);

  const emitVoucher = useCallback(async () => {
    const success = await paymentProcessHelper.emitVoucher(
      parsedData,
      reserveData
    );

    if (success?.data?.status) {
      window.location.href = `${basePath}/success/?reserve_id=${reserveData?.reserve_id}&method=prisma`;
    } else {
      alert("Error!!");
    }
    console.info("emitVoucher done");
  }, [reserveData, parsedData, basePath]);

  const fetchPay = useCallback(async () => {
    setActionButtonEnabled(false);
    const payData = removeBeneficiaryKeys(reserveData);

    // console.log(parsedData);

    const success = await paymentProcessHelper.fetchPay({
      ...payData,
      ...{
        method: "Decidir",
        country_id: 164,
        reserve_id: reserveData?.reserve_id,
        token: prismaResponse?.id,
        payment_method_id: brand,
        installments: dues,
        bin: prismaResponse?.bin,
        device_unique_identifier: decidirInstance?.device_unique_identifier,
        email: parsedData["beneficiary[email][0]"],
        fullname: `${parsedData["beneficiary[name][0]"]} ${parsedData["beneficiary[lastname][0]"]}`,
      },
    });

    if (success?.data?.status) {
      await emitVoucher();
      setActionButtonEnabled(true);
    } else {
      setLoader(false);
      console.error("FetchPay Error", success?.data?.err);
      setRequestError(success?.data?.err);
      setActionButtonEnabled(true);
      if (!isLink) {
        setBillingState(false);
        setReserveData(parsedData);
      }
    }
    console.log("fetchPay done");
  }, [
    reserveData,
    decidirInstance,
    prismaResponse,
    brand,
    parsedData,
    dues,
    isLink,
  ]);

  useEffect(() => {
    if (billingState && !isLink) {
      fetchPay();
    }
  }, [billingState, isLink]);

  useEffect(() => {
    if (billingData && !isLink) {
      fetchBilling();
    }
  }, [billingData, isLink]);

  useEffect(() => {
    if (prismaResponse) {
      if (isLink) {
        fetchPay();
      } else {
        emit();
      }
    }
  }, [prismaResponse, isLink]);

  return (
    <>
      {requestError && <WhatsappSupport reserve_id={reserveData?.reserve_id} />}

      <form ref={formRef} onSubmit={handleSubmit(onSubmit)} id='form'>
        <Card>
          <CardContent>
            <div className='mb-8'>
              <h1 className='font-bold text-2xl text-[#006FE8] mb-8'>
                {t("steps.payment.title.creditCard")}
              </h1>
              <FormGroup>
                <Controller
                  name={`card_id`}
                  rules={{ required: ERROR_MESSAGES.form.REQUIRED }}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      select
                      {...field}
                      error={!!errors[`card_id`]}
                      helperText={errors[`card_id`]?.message}
                      onChange={async e => {
                        await onBrand(e.target.value);
                        await field.onChange(e.target.value);
                      }}
                    >
                      {cardsList.map((row, index) => (
                        <MenuItem key={index} value={row.value}>
                          {row.key}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </FormGroup>
            </div>
            <h1 className='font-bold text-2xl text-[#006FE8] mb-8'>
              {t("steps.payment.cardDataFormTitle")}
            </h1>
            <FormGroup className='mb-4'>
              <label className='font-bold text-xs mb-2 text-[#596E80]'>
                {t("form.label.cardNumber")}
              </label>
              <Controller
                name={`card_number`}
                control={control}
                rules={{ required: ERROR_MESSAGES.form.REQUIRED }}
                render={({ field }) => (
                  <InputMask
                    mask={mask?.value}
                    value={field.value}
                    onChange={e => {
                      field.onChange(e.target.value);
                    }}
                  >
                    {() => (
                      <TextField
                        fullWidth
                        error={!!errors[`card_number`]}
                        helperText={errors[`card_number`]?.message}
                        placeholder={t("form.placeholder.cardNumber")}
                        inputProps={{ "data-decidir": "card_number" }}
                        InputProps={{
                          startAdornment: <CreditCardIcon />,
                        }}
                      />
                    )}
                  </InputMask>
                )}
              />
            </FormGroup>
            <div className='mb-4 flex justify-between'>
              <FormGroup className='w-full mr-6'>
                <label className='font-bold text-xs mb-2 text-[#596E80] truncate w-24 sm:w-full'>
                  {t("form.label.expiration")}
                </label>
                <Controller
                  name={`card_date_exp`}
                  control={control}
                  rules={{
                    required: ERROR_MESSAGES.form.REQUIRED,
                  }}
                  render={({ field }) => (
                    <InputMask
                      mask='99/99'
                      value={field.value}
                      onChange={e => {
                        field.onChange(e.target.value);
                        setExpDate(e.target.value);
                      }}
                    >
                      {() => (
                        <TextField
                          fullWidth
                          error={!!errors[`card_date_exp`]}
                          helperText={errors[`card_date_exp`]?.message}
                          placeholder={t("form.placeholder.expiration")}
                        />
                      )}
                    </InputMask>
                  )}
                />
              </FormGroup>
              <FormGroup className='w-full'>
                <label className='font-bold text-xs mb-2 text-[#596E80]'>
                  {t("form.label.securityCode")}
                </label>
                <Controller
                  name={`security_code`}
                  control={control}
                  rules={{ required: ERROR_MESSAGES.form.REQUIRED }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      inputProps={{
                        "data-decidir": "security_code",
                        maxLength: mask?.csv,
                      }}
                      InputProps={{
                        startAdornment: <LockIcon />,
                      }}
                      error={!!errors[`security_code`]}
                      helperText={errors[`security_code`]?.message}
                      placeholder={t("form.placeholder.securityCode")}
                    />
                  )}
                />
              </FormGroup>
            </div>
            <FormGroup className='mb-4'>
              <label className='font-bold text-xs mb-2 text-[#596E80]'>
                {t("form.label.cardholderDocument")}
              </label>
              <Controller
                name={`card_holder_doc_number`}
                control={control}
                rules={{ required: ERROR_MESSAGES.form.REQUIRED }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={!!errors[`card_holder_doc_number`]}
                    helperText={errors[`card_holder_doc_number`]?.message}
                    placeholder={t("form.placeholder.cardholderDocument")}
                    inputProps={{ "data-decidir": "card_holder_doc_number" }}
                  />
                )}
              />
            </FormGroup>
            <FormGroup className='mb-4'>
              <label className='font-bold text-xs mb-2 text-[#596E80]'>
                {t("form.label.cardholder")}
              </label>
              <Controller
                name={`card_holder_name`}
                control={control}
                rules={{ required: ERROR_MESSAGES.form.REQUIRED }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    inputProps={{ "data-decidir": "card_holder_name" }}
                    error={!!errors[`card_holder_name`]}
                    helperText={errors[`card_holder_name`]?.message}
                    placeholder={t("form.placeholder.cardholder")}
                  />
                )}
              />
            </FormGroup>
            {paymentMethod !== "decidirDebit" && (
              <FormGroup className='mb-4'>
                <label className='font-bold text-xs mb-2 text-[#596E80]'>
                  {t("form.label.dues")}
                </label>
                <Controller
                  name={`dues`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      select
                      {...field}
                      error={!!errors[`dues`]}
                      onChange={e => {
                        setDues(e.target.value);
                      }}
                      defaultValue='1'
                    // helperText={`(*) ${prismaInstallments.length === 3 ? '2' : '2, 4 y 5'} cuotas tienen recargo. El interés lo determina el banco emisor de tu tarjeta.`}
                    >
                      {prismaInstallments.map((row, index) => (
                        <MenuItem key={index} value={row.value}>
                          {row.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </FormGroup>
            )}
            <Alert
              severity='error'
              id='card-errors'
              className={!requestError ? "!hidden" : "flex"}
            >
              {requestError}
            </Alert>
          </CardContent>
        </Card>
        <input type='hidden' data-decidir='card_holder_doc_type' value='dni' />
        <input
          type='hidden'
          data-decidir='card_expiration_month'
          value={expDateMonth}
        />
        <input
          type='hidden'
          data-decidir='card_expiration_year'
          value={expDateYear}
        />
      </form>
    </>
  );
};

export default PrismaCreditCardForm;
